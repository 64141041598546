.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.image {
  background-size: cover;
  background-position: center;
  height:  calc(52vw);
  width:  calc(80vw);
  border: 5px solid #000000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.phone .image {
  width:  calc(24vw);
}
.notch {
  position: absolute;
  left:  calc(34.6vw);
  height: calc(1.6vw);
  width:  calc(11vw);
  border-bottom-left-radius: calc(.625vw);
  border-bottom-right-radius: calc(.625vw);
  top:  0px;
  background-color: #000000;
  z-index: 5;
}
.phone .notch {
  left:  calc(8vw);
  width:  calc(8.5vw);
  height:  calc(1.8vw);
}
.phone .bar {
  height:  calc(1.8vw);
}
.phone .download, .phone .loading {
  white-space: nowrap;
  width:  300px;
  font-size: 34px;
  height:  56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone .loading {
  padding: 0;
}
.bar {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(1.6vw);
  background-color: #000000;
}
.loading {
  border:  3px solid #000;
  color:  #e8d6ca;
  font-family: "Bebas Neue";
  font-size:  34px;
  background-color: #000000c7;
  padding:  10px 20px;
  border-radius:  35px;
  text-align: center;
}
.settings {
  color:  #e8d6ca;
  font-family: "Bebas Neue";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size:  20px;
  width:  calc(80vw - 30px);
  background-color: #000000c7;
  padding:  10px 20px;
  border-radius:  10px;
  margin-top:  10px;
}
.phone .settings {
  width: calc(40vw);
}
.settings figure {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #ffc904;
}
.device {
  flex: 1;
  display: flex;
  align-items: center;
}
.model {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.settings label {
  color:  #e8d6ca;
  white-space: nowrap;
  margin-right:  10px;
}
.download {
  border:  3px solid #426c83;
  cursor: pointer;
  border-radius:  35px;
  background-image: url('/public/img/button-background-blue.png');
  font-family: "Bebas Neue";
  font-size:  34px;
  color:  #e8d6ca;
  width: 365px;
  height: 56px;
  box-shadow: 0px 0px 15px -4px #000000;
}
.reselect {
  border:  3px solid #a1170f;
  cursor: pointer;
  border-radius:  15px;
  background-color: #d61d21cc;
  font-family: "Bebas Neue";
  font-size:  34px;
  color:  #fff;
  width: 365px;
  height: 56px;
  margin-top:  15px;
}
@media only screen and (max-width : 1400px) {
  .phone .image {
    height:  757px;
    width: 350px;
  }
  .phone .notch {
    height: 30px;
    width:  150px;
    border-bottom-left-radius: 18px;
    left: 100px;
    border-bottom-right-radius: 18px;
  }
  .phone .bar {
    height: 30px;
  }
}
@media only screen and (max-width : 500px) {
  .settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .model {
    margin-left: 0px;
    margin-top:  15px;
  }
  .download {
    width:  253px;
  }
  .reselect {
    width:  253px;
    height:  39px;
    font-size:  20px;
  }
}
